import React from "react";
import { graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import {
  faCode,
  faGlobe,
  faServer,
  faMobile,
  faDatabase,
  faCloud,
  faVial,
  faCogs,
} from "@fortawesome/free-solid-svg-icons";
import Layout from "../components/layout";
import Seo from "../components/seo";
import PageTitle from "../components/page_title";

type Project = {
  title: string;
  description: string;
  image: {
    url: string;
    alt: string;
  };
  links: {
    title: string;
    link: string;
  }[];
  technologies: string[];
};

type ProjectsProps = {
  data: {
    allProjectsJson: {
      nodes: Project[];
    };
  };
};

const ProjectCard: React.FC<{ project: Project }> = ({ project }) => {
  const { title, description, image, links } = project;

  return (
    <div className="bg-surface rounded-lg overflow-hidden shadow-lg flex flex-col h-full transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
      {image && (
        <div className="relative pb-[90%]">
          <img
            className="absolute top-0 left-0 w-full h-full object-cover"
            src={image.url}
            alt={image.alt}
            loading="lazy"
          />
        </div>
      )}
      <div className="p-6 flex-grow">
        <h3 className="font-bold text-xl mb-2 text-primary">{title}</h3>
        <p className={`text-text-secondary ${links && links.length > 0 ? 'mb-4' : 'mb-0'}`}>{description}</p>
        {links && links.length > 0 && (
          <div className="flex flex-wrap gap-2 mb-0">
            {links.map(link => (
              <a
                key={link.title}
                className="inline-flex items-center gap-1 text-sm text-link hover:text-link-hover transition-colors duration-200"
                href={link.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.title} <FontAwesomeIcon icon={faLink} className="text-xs" />
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const technologies = [
  { category: "Languages", icon: faCode, items: ["JavaScript", "TypeScript", "Ruby", "PHP", "C#"] },
  { category: "Frontend", icon: faGlobe, items: ["React", "Vue.js", "Angular", "Next.js", "Gatsby"] },
  { category: "Backend", icon: faServer, items: ["Node.js", "Ruby on Rails", "Laravel", "Express.js"] },
  { category: "Mobile", icon: faMobile, items: ["React Native", "Expo"] },
  { category: "Databases", icon: faDatabase, items: ["PostgreSQL", "MySQL", "MongoDB", "Redis"] },
  { category: "DevOps", icon: faCloud, items: ["Docker", "AWS", "Azure", "CI/CD"] },
  { category: "Testing", icon: faVial, items: ["Jest", "React Testing Library", "Cypress", "RSpec", "PHPUnit", "Pest"] },
  { category: "Other", icon: faCogs, items: ["GraphQL", "REST APIs", "WebSockets", "Microservices"] },
];

const Projects: React.FC<ProjectsProps> = ({ data }) => {
  const projects = data.allProjectsJson.nodes;

  return (
    <Layout>
      <PageTitle>My Work</PageTitle>

      <section className="mb-12">
        <h2 className="text-2xl font-bold mb-6 text-primary">Projects</h2>
        <p className="text-text-secondary mb-8">
          I've had the opportunity to work on various personal and professional projects over the years.
          Here's a showcase of some of my personal work. For information about my professional projects,
          please feel free to reach out to me directly.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
          {projects.map((project, index) => (
            <ProjectCard key={`project-${index}`} project={project} />
          ))}
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-bold mb-6 text-primary">Technologies I'm Familiar With</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {technologies.map((tech, index) => (
            <div key={index} className="bg-surface rounded-lg px-6 py-4 shadow-md flex flex-col items-center text-center">
              <div className="text-4xl text-primary mb-4">
                <FontAwesomeIcon icon={tech.icon} />
              </div>
              <h3 className="text-lg font-semibold mb-3 text-secondary">{tech.category}</h3>
              <ul className="text-text-secondary text-sm mb-0">
                {tech.items.map((item, itemIndex) => (
                  <li key={itemIndex} className="mb-1">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
};

export default Projects

export const Head = () => <Seo title="Projects & Technologies | User-Centric Web Development" />;

export const pageQuery = graphql`
  query {
    allProjectsJson {
      nodes {
        title
        description
        image {
          url
          alt
        }
        links {
          title
          link
        }
        technologies
      }
    }
  }
`;
